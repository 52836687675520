App.DependencyManager = (function () {
  function showFields ($container) {
    $container.show()
    $container.find('input,select').removeAttr('disabled')
  }

  function hideFields ($container) {
    $container.hide()
    $container.find('input,select').attr('disabled', 'disabled')
  }

  return {
    toggleFields: function ($container1, $container2) {
      if ($container1.is(':hidden')) {
        showFields($container1)
        hideFields($container2)
      } else {
        showFields($container2)
        hideFields($container1)
      }
    }
  }
}())
