import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  removeAttribute (event) {
    event.preventDefault()
    const link = event.currentTarget
    if ($(link).hasClass('report-close') && $('.nested_attribute').length === 1) {
      return
    }
    $(link).closest('.nested_attribute').remove()
  }

  addAttribute (event) {
    event.preventDefault()
    const link = event.currentTarget // use currentTarget because user might click on the <span class="fas fa-plus"> element inside

    const time = new Date().getTime()
    const regexp = new RegExp($(link).data('id'), 'g')
    const to_insert = $(link).data('fields').replace(regexp, time)
    $(to_insert).insertBefore($(link))
  }
}