export const filterToPayload = function(form) {
    let arrayData, objectData;
    arrayData = $(form).serializeArray();
    objectData = {};
    $.each(arrayData, function () {
        let value;
        if (this.value != null) {
            value = this.value;
        } else {
            value = '';
        }
        if (objectData[this.name] != null) {
            if (!objectData[this.name].push) {
                objectData[this.name] = [objectData[this.name]];
            }
            objectData[this.name].push(value);
        } else {
            objectData[this.name] = value;
        }
    });
    return objectData
}
