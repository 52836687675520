
$(document).ready(function () {
  const person_table = $('.person_updates_table').DataTable({
    dom: 'frtp',
    paging: false,
    order: [],
    searching: false,
    columnDefs: [{
      targets: 0,
      checkboxes: {
        selectRow: true,
        selectAll: false
      }
    }],
    select: 'multi'
  })

  person_table.on('select.dt deselect.dt', function (e, dt, type, indexes) {
    if (type === 'row') {
      updateSelectButton(person_table.rows('.selected').any())
    }
  })

  $('#update-all-button').on('click', function (e) {
    $('<input>')
      .attr({
        type: 'hidden',
        id: 'thing',
        name: 'updates',
        value: JSON.stringify(
          person_table
            .rows()
            .data()
            .toArray()
        )
      })
      .appendTo('.person_update_form')
  })

  $('#update-selected-button').on('click', function (e) {
    $('<input>')
      .attr({
        type: 'hidden',
        id: 'thing',
        name: 'updates',
        value: JSON.stringify(
          person_table
            .rows('.selected')
            .data()
            .toArray()
        )
      })
      .appendTo('.person_update_form')
  })
})

function updateSelectButton (state) {
  $('#update-selected-button').attr('disabled', !state)
}
