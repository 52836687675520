import {Controller} from '@hotwired/stimulus'

export default class extends Controller {

    connect() {
        var form = this.element
        form.addEventListener('submit', function (event) {

            form.classList.add('was-validated')
            var errorElements = form.querySelectorAll(".invalid");
            if (!form.checkValidity()) {
                event.preventDefault()
                event.stopPropagation()
                $('html, body').animate({
                    scrollTop: $(errorElements[0]).offset().top
                }, 200);

            }

        }, false)
    }
}
