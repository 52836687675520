import {Controller} from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'

const getPartContainer = currentTarget => currentTarget.closest("[data-amount-divide-target='partContainer']")
const getTargetsWithin = (target, scope) => scope.querySelectorAll(`[data-amount-divide-target='${target}']`)

export default class extends Controller {
  static targets = ['sumAmount', 'percent', 'partAmount', 'partContainer']

  onPercentChange(e) {
    this.updatePartAmountsByPercent(e.currentTarget)
  }

  onPartAmountChange(e) {
    const partAmountTarget = e.currentTarget
    const partAmount = AutoNumeric.getNumber(partAmountTarget)
    const sumAmountTarget = this.sumAmountTargets.find(el =>
      el.dataset.amountType === partAmountTarget.dataset.amountType
    )
    const sumAmount = AutoNumeric.getNumber(sumAmountTarget)
    const percentTarget = getTargetsWithin('percent', getPartContainer(partAmountTarget))[0]
    percentTarget.value = Math.round(partAmount / sumAmount * 100)
    this.updatePartAmountsByPercent(percentTarget, partAmountTarget)
  }

  onSumAmountChange(e) {
    this.partContainerTargets.forEach(partContainer => {
      const percentEl = getTargetsWithin('percent', partContainer)[0]
      this.updatePartAmountsByPercent(percentEl)
    })
  }

  updatePartAmountsByPercent(percentEl, except = null) {
    if (!percentEl.value) return
    const partContainer = getPartContainer(percentEl)
    this.sumAmountTargets.forEach(sumAmountTarget => {
      const sumAmount = AutoNumeric.getNumber(sumAmountTarget)
      if (!sumAmount) return
      const partAmount = sumAmount * percentEl.value / 100
      const partAmountTarget = Array.from(getTargetsWithin('partAmount', partContainer)).find(el =>
        el.dataset.amountType === sumAmountTarget.dataset.amountType
      )
      if (partAmountTarget === except) return
      AutoNumeric.set(partAmountTarget, partAmount)
    })
  }
}