import {Controller} from '@hotwired/stimulus'
import TomSelect from "tom-select"

export default class extends Controller {
    static values = {placeholder: String}

    connect() {
        new TomSelect(this.element, {
            plugins: {
                remove_button: {
                    title: 'Remove this item',
                }
            },
            placeholder: this.placeholderValue || 'Type PMID, PMCID, title, DOI or other terms to search PubMed',
            valueField: 'pmid',
            labelField: 'title',
            searchField: ['pmid', 'title', 'lastauthor', 'sorttitle', 'articleids', 'nlmuniqueid', 'fulljournalname', 'elocationid', 'sortfirstauthor', 'json'],
            load: function (query, callback) {
                var url = 'https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esearch.fcgi?db=pubmed&retmode=json&term=' + encodeURIComponent(query);
                fetch(url)
                    .then(response => response.json())
                    .then(json => {
                        const pmids = json.esearchresult.idlist
                        return fetch("https://eutils.ncbi.nlm.nih.gov/entrez/eutils/esummary.fcgi?db=pubmed&retmode=json&id=" + pmids.join())
                    })
                    .then(response => response.json())
                    .then(json => {
                        const uids = json.result.uids
                        var articles = uids.map(uid => json.result[uid]).map(e => ({...e,
                            pmid: e.uid,
                            json: JSON.stringify(e)
                        }))
                        callback(articles);
                    }).catch(() => {
                    callback();
                });
            },
            render: {
                option: function (item, escape) {
                    return `
<div class="py-2">
    <div>${escape(item.title)}</div>
    <small class="text-secondary">
        ${escape(item.sortfirstauthor)}, et al.
        <span class="text-success">
            ${escape(item.source)}.
            ${escape(item.pubdate)}.
            PMID: ${escape(item.pmid)}
        </span>
    </small>
</div>`;
                },
                item: function (item, escape) {
                    return `<div class="py-2 d-flex">${escape(item.title)}</div>`;
                },
            },
        });
    }
}
