$(document).ready(function () {
  table = $('#events-list').DataTable({
    dom: '<"#events-list-header"Blf>t<"#events-list-footer"ip>',
    autoWidth: true,
    lengthMenu: [
      [10, 25, 50, -1],
      [10, 25, 50, 'All']
    ],
    paging: true,
    buttons: [{
      extend: 'colvis',
      text: '<i class="fa fa-eye"></i> Select Columns',
      titleAttr: 'Columns',
      className: 'btn btn-sm btn-outline-dark'
    }]
  });
  if ($('#events-list').length) {
    yadcf.init(table, [
      {
        column_number: 0,
        column_selector: 'title:name',
        filter_type: "text",
        column_data_type: 'text',
      },
      {
        column_number: 1,
        column_selector: 'dates:name',
        filter_type: "range_date",
        datepicker_type: 'bootstrap-datepicker',
        date_format: 'mm/dd/yyyy',
        html5_data: 'data-search',
        filter_plugin_options: {
          todayBtn: "linked",
          clearBtn: true,
          autoclose: true,
          todayHighlight: true
        }
      },
      {
        column_number: 2,
        column_selector: 'speaker:name',
        filter_type: "text",
        column_data_type: 'text',
      },
      {
        column_number: 3,
        column_selector: 'sublectures:name',
        filter_type: "text",
        column_data_type: 'text',
      },
      {
        column_number: 4,
        column_selector: 'programs:name',
        filter_type: 'multi_select',
        select_type: 'select2',
        filter_reset_button_text: false,
        text_data_delimiter: ',',
        column_data_type: 'html',
        html_data_type: 'text',
        select_type_options: {
          allowClear: true,
          minimumResultsForSearch: -1,
          theme: 'bootstrap'
        }
      }
    ]);
  }
});
