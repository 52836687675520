import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  formatTag (option) {
    if (!option.element) {
      return option.text
    } else {
      return '<span class=\'tag-dropdown-color\' style=\'background-color: ' +
        option.element.dataset.color + '\'>&nbsp;</span> ' + option.text
    }
  }

  connect () {
    $(this.element).select2({
      theme: 'bootstrap',
      escapeMarkup: function (markup) {
        return markup
      },
      templateResult: (option) => this.formatTag(option),
      templateSelection: (option) => this.formatTag(option)
    })
  }
}
