import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {showSelectAll: Boolean}

  connect () {
    if (!$(this.element).hasClass('select2-hidden-accessible')) {
      let dropdownParent = $(document.body)
      if (document.body.classList.contains('modal-open') && $(this.element).parents('.modal.show:first').length !== 0) {
        dropdownParent = $(this.element).parents('.modal.show:first .modal-content')
      }
      $(this.element).select2({
        tokenSeparators: [','],
        theme: 'bootstrap',
        placeholder: this.element.getAttribute('placeholder'),
        allowClear: this.element.dataset.select2AllowClear,
        closeOnSelect: true,
        dropdownParent: dropdownParent
      })
      if (this.showSelectAllValue) {
        const selectId = this.element.id;
        $(this.element).parent().append(`<div class="form-check">
<input class="form-check-input" type="checkbox" id="${selectId}-select-all">
  <label class="form-check-label" for="${selectId}-select-all">
    Check All
  </label>
</div>`)
        $(`#${selectId}-select-all`).click(function () {
          if ($(this).is(':checked')) {
            $(`#${selectId} > option`).prop("selected", "selected");
            $(`#${selectId}`).trigger("change");
          } else {
            $(`#${selectId}`).val('').change();
          }
        });
      }
    }
  }
}
