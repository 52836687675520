import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {anyMatchPubDate: Boolean, include: Boolean, exclude: Boolean, isAuthor: Boolean, cancerRelevant: Boolean}
  static targets = ["isPersonProgramUsed", "pubPersonProgramFields", "personProgramCell", "membershipRow"]

  initialize() {
    this.togglePersonProgramFields();
    this.updateIsPersonProgramUsed();
  }

  togglePersonProgramFields() {
    if (this.cancerRelevantValue && this.isAuthorValue) {
      this.pubPersonProgramFieldsTarget.removeAttribute("disabled")
    } else {
      this.pubPersonProgramFieldsTarget.setAttribute("disabled", "")
    }
  }

  updateIsPersonProgramUsed() {
    if (this.includeValue && this.excludeValue) {
      this.isPersonProgramUsedTarget.innerHTML = "ERROR: cannot be excluded and included at the same time!"
      this.isPersonProgramUsedTarget.className = "bg-danger text-white";
      return
    }
    const isUsed = this.cancerRelevantValue && this.isAuthorValue && (this.includeValue || (this.anyMatchPubDateValue && !this.excludeValue));
    this.personProgramCellTargets.forEach(el => {
      if (isUsed) {
        el.classList.add("table-success")
        el.classList.remove("table-secondary")
      } else {
        el.classList.remove("table-success")
        el.classList.add("table-secondary")
      }
    });
    this.membershipRowTargets.forEach(row => {
      row.classList.toggle("list-group-item-secondary", !isUsed)
    });
    let reason;
    const isMultiMemberships = this.membershipRowTargets.length > 1;
    if (!this.cancerRelevantValue) {
      reason = "Publication is not cancer relevant"
    } else if (this.isAuthorValue) {
      if (this.includeValue) {
        reason = "Included regardless of dates"
      } else if (this.anyMatchPubDateValue) {
        if (this.excludeValue) {
          reason = `Publication date matches ${isMultiMemberships ? "one of the memberships" : "the membership"} but excluded`
        } else {
          reason = `Publication date matches ${isMultiMemberships ? "one of the memberships" : "the membership"}`
        }
      } else {
        reason = `Publication date does not match ${isMultiMemberships ? "any of the memberships" : "the membership"}`
      }
    } else {
      reason = 'Member is not author'
    }
    this.isPersonProgramUsedTarget.innerHTML = isUsed ? 'Yes' : 'No';
    this.isPersonProgramUsedTarget.innerHTML += ` <small class="text-muted">(${reason})</small>`;
    this.isPersonProgramUsedTarget.className = isUsed ? 'text-success' : 'text-danger';
  }

  changeExclude(event) {
    this.excludeValue = event.target.checked;
  }

  changeInclude(event) {
    this.includeValue = event.target.checked;
  }

  excludeValueChanged() {
    this.updateIsPersonProgramUsed();
  }

  includeValueChanged() {
    this.updateIsPersonProgramUsed();
  }

  isAuthorValueChanged() {
    this.togglePersonProgramFields();
    this.updateIsPersonProgramUsed();
  }

  cancerRelevantValueChanged() {
    this.togglePersonProgramFields();
    this.updateIsPersonProgramUsed();
  }
}
