import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ['input'];
  static values = {ifBlank: Boolean, ifEqual: String, ifPresent: Boolean, ifChecked: Boolean};

  connect() {
    this.updateOtherInputs(
      this.inputTargets[0]
    );
  }

  updateVisibility(event) {
    this.updateOtherInputs(event.target);
  }

  updateOtherInputs(currentInput) {
    let disable;
    if (this.ifBlankValue) {
      disable = !currentInput.value;
    } else if (this.ifPresentValue) {
      disable = !!currentInput.value;
    } else if (this.ifEqualValue) {
      disable = currentInput.value === this.ifEqualValue;
    } else if (this.ifCheckedValue) {
      disable = currentInput.checked;
    }
    this.inputTargets.filter(el => el !== currentInput).forEach(el => {
      const prevStatus = el.disabled;
      el.disabled = disable;
      if (disable !== prevStatus) {
        const evt = new Event('change', { bubbles: true });
        el.dispatchEvent(evt);
      }
    });
  }
}