import { Controller } from '@hotwired/stimulus'
import { currentPersonId } from '../src/auth'

export default class extends Controller {
    static targets = ['actions']
    static values = {
        authorId: String,
    }

    toggleActions() {
        if (this.hasActionsTarget && this.authorIdValue === currentPersonId()) {
            this.actionsTarget.classList.toggle('invisible')
        }
    }
}