import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {createMultipleUrl: String}

  connect() {
    this.element[this.identifier] = this;
  }

  createMultiple(event) {
    event.preventDefault();
    event.target.disabled = true;
    const selectedPubIds = this.element['publication-search'].selectedPublicationIds();
    const data = new FormData();
    selectedPubIds.forEach((pubId) => data.append("publication_ids[]", pubId));
    const token = document.querySelector('meta[name="csrf-token"]').content;
    fetch(this.createMultipleUrlValue, {
      method: "POST",
      headers: {
        "X-CSRF-Token": token,
      },
      body: data,
    }).then((response) => {
      if (response.redirected) {
        window.location.href = response.url;
      } else {
        event.target.disabled = false;
      }
    });
  }
}

