import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  dt = null

  connect () {
    if (!this.element.classList.contains('dataTable')) {
      this.dt = $(this.element).DataTable({
        order: [],
      })
    }
  }
}
