import Readmore from 'readmore-js'
import 'select2'

window.App = window.App || {}
$.fn.select2.defaults.set('width', '100%')

App.init = function ($scope) {
  $scope.find('input.datepicker').datepicker({
    format: 'yyyy-mm-dd',
    todayBtn: 'linked',
    clearBtn: true,
    autoclose: true,
    todayHighlight: true
  })

  $scope.find('.select2-input').each(function () {
    $(this).select2({
      tokenSeparators: [','],
      theme: 'bootstrap',
      allowClear: this.dataset.select2allowclear
    })
  })

  $scope.find('[data-bs-toggle="tooltip"]').tooltip()
}

$(document).ready(function () {
  App.init($(document))

  $('#group_selection').select2ToTree({
    placeholder: 'Select one or more groups',
    theme: 'bootstrap',
    closeOnSelect: false,
    tokenSeparators: [',']
  })

  $('.basic-table').DataTable({
    dom: 'frtp',
    paging: false,
    order: [],
    searching: false
  })

  new Readmore('.possible-long-text', {
    collapsedHeight: 40
  })

  $('form').on('cocoon:after-insert', function (e, insertedItem, originalEvent) {
    App.init(insertedItem)
  })
});

(function () {
  if ($) {
    const token = $('meta[name="csrf-token"]').attr('content')

    $.ajaxSetup({
      beforeSend: function (xhr) {
        xhr.setRequestHeader('X-CSRF-Token', token)
      }
    })
  }
})()
