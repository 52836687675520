import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["cancerRelevantInputWrapper", "cancerRelevantDependant", "notCancerRelevantDependant",
    "personProgram", "isAuthor",
    "memberSelectedCore", "adminCore", "coresInvalidFeedback"
  ]

  toggleCancerRelevantDependants() {
    if (this.hasCancerRelevantInputWrapperTarget) {
      const checkedOption = this.cancerRelevantInputWrapperTarget.querySelector(
        "input:checked");
      const cancerRelevant = checkedOption && checkedOption.value === 'true';
      this.cancerRelevantDependantTargets.forEach(
        el => el.hidden = !cancerRelevant);
      this.notCancerRelevantDependantTargets.forEach(
        el => el.hidden = cancerRelevant);
      this.personProgramTargets.forEach(el => {
        el.dataset.publicationPersonProgramCancerRelevantValue = cancerRelevant;
      });
    }
  }

  updateIsAuthorValue(target) {
    const isAuthor = target.checked;
    const personId = target.dataset.personId;
    this.personProgramTargets.forEach(el => {
      if (el.dataset.personId === personId) {
        el.dataset.publicationPersonProgramIsAuthorValue = isAuthor;
      }
    });
  }

  changeIsAuthor(event) {
    this.updateIsAuthorValue(event.target);
  }

  toggleAllIsAuthors(event) {
    this.isAuthorTargets.forEach(el => {
      el.checked = event.target.checked;
      this.updateIsAuthorValue(el);
    });
  }

  checkCores() {
    let isSucc = true;
    this.memberSelectedCoreTargets.forEach(memberCore => {
      this.adminCoreTargets.forEach(adminCore => {
        if (adminCore.value === memberCore.dataset.groupId && !adminCore.checked) {
          adminCore.setCustomValidity("Please verify");
          memberCore.closest('td').classList.add('table-warning');
          document.getElementById('core-facilities').scrollIntoView();
          adminCore.closest('td').classList.add('table-warning');
          isSucc = false;
        }
      })
    });
    return isSucc;
  }

  connect() {
    this.toggleCancerRelevantDependants();
    const form = this.element;
    this.showingConfirm = false;
    if (window.location.href.endsWith('#core-facilities')) {
      this.highlightMissingCores()
    }
    form.addEventListener('submit', event=>{
      this.highlightMissingCores(event)
    })
  }

  highlightMissingCores(event) {
    if (!this.showingConfirm && !this.checkCores()) {
      this.coresInvalidFeedbackTarget.style.display = "block";
      this.showingConfirm = true;
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      this.element.classList.add('was-validated');
    }
  }
}
