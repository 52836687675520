// Load all the controllers within this directory and all subdirectories.
// Controller files must be named *_controller.js.

import { Application } from '@hotwired/stimulus'
import { definitionsFromContext } from '@stimulus/webpack-helpers'
import CheckboxSelectAll from 'stimulus-checkbox-select-all'
import Timeago from 'stimulus-timeago'
import TextareaAutogrow from 'stimulus-textarea-autogrow'
import ReadMore from "stimulus-read-more"


const application = Application.start()
const context = require.context("controllers", true, /_controller\.js$/)
application.load(definitionsFromContext(context))

application.register('checkbox-select-all', CheckboxSelectAll)
application.register('timeago', Timeago)
application.register('textarea-autogrow', TextareaAutogrow)
application.register("read-more", ReadMore)

