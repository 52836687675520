import {Controller} from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['originalValue', 'overrideValue', 'overrideValueContainer']

  enableOverrideValue() {
    this.originalValueTarget.classList.add('text-decoration-line-through')
    this.overrideValueContainerTarget.style.display = 'block'
  }

  disableOverrideValue() {
    this.originalValueTarget.classList.remove('text-decoration-line-through')
    this.overrideValueTarget.value = ""
    this.overrideValueContainerTarget.style.display = 'none'
  }

  toggleOverrideValue(e) {
    e.currentTarget.checked ? this.enableOverrideValue() : this.disableOverrideValue()
  }
}