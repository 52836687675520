/**
 *
 * Modified to use Bootstrap 4 theme. -Fang 11/11/2020
 *
 * ----------------------------------------
 * Sometimes for quick navigation, it can be useful to allow an end user to
 * enter which page they wish to jump to manually. This paging control uses a
 * text input box to accept new paging numbers (arrow keys are also allowed
 * for), and four standard navigation buttons are also presented to the end
 * user.
 *
 *  @name Navigation with text input
 *  @summary Shows an input element into which the user can type a page number
 *  @author [Allan Jardine](http://sprymedia.co.uk)
 *  @author [Gordey Doronin](http://github.com/GDoronin)
 *
 *  @example
 *    $(document).ready(function() {
 *        $('#example').dataTable( {
 *            "pagingType": "input"
 *        } );
 *    } );
 */

(function ($) {
  function calcDisableClasses (oSettings) {
    const start = oSettings._iDisplayStart
    const length = oSettings._iDisplayLength
    const visibleRecords = oSettings.fnRecordsDisplay()
    const all = length === -1

    // Gordey Doronin: Re-used this code from main jQuery.dataTables source code. To be consistent.
    const page = all ? 0 : Math.ceil(start / length)
    const pages = all ? 1 : Math.ceil(visibleRecords / length)

    const disableFirstPrevClass = (page > 0 ? '' : oSettings.oClasses.sPageButtonDisabled)
    const disableNextLastClass = (page < pages - 1 ? '' : oSettings.oClasses.sPageButtonDisabled)

    return {
      first: disableFirstPrevClass,
      previous: disableFirstPrevClass,
      next: disableNextLastClass,
      last: disableNextLastClass
    }
  }

  function calcCurrentPage (oSettings) {
    return Math.ceil(oSettings._iDisplayStart / oSettings._iDisplayLength) + 1
  }

  function calcPages (oSettings) {
    return Math.ceil(oSettings.fnRecordsDisplay() / oSettings._iDisplayLength)
  }

  const firstClassName = 'first'
  const previousClassName = 'previous'
  const nextClassName = 'next'
  const lastClassName = 'last'

  const paginateClassName = 'paginate'
  const paginatePageClassName = 'paginate_page'
  const paginateInputClassName = 'paginate_input'
  const paginateTotalClassName = 'paginate_total'

  $.fn.dataTableExt.oPagination.input = {
    fnInit: function (oSettings, nPaging, fnCallbackDraw) {
      const pagList = document.createElement('ul')
      const nFirst = document.createElement('li')
      const nPrevious = document.createElement('li')
      const nNext = document.createElement('li')
      const nLast = document.createElement('li')
      const nInput = document.createElement('input')
      const nTotal = document.createElement('span')
      const nInfo = document.createElement('span')

      const language = oSettings.oLanguage.oPaginate
      const classes = oSettings.oClasses
      let info = language.info || 'Page _INPUT_ of _TOTAL_'

      nFirst.innerHTML = '<a class="page-link" href="#">' + language.sFirst + '</a>'
      nPrevious.innerHTML = '<a class="page-link" href="#">' + language.sPrevious + '</a>'
      nNext.innerHTML = '<a class="page-link" href="#">' + language.sNext + '</a>'
      nLast.innerHTML = '<a class="page-link" href="#">' + language.sLast + '</a>'

      nFirst.className = firstClassName + ' ' + classes.sPageButton
      nPrevious.className = previousClassName + ' ' + classes.sPageButton
      nNext.className = nextClassName + ' ' + classes.sPageButton
      nLast.className = lastClassName + ' ' + classes.sPageButton

      nInput.className = paginateInputClassName
      nTotal.className = paginateTotalClassName

      if (oSettings.sTableId !== '') {
        nPaging.setAttribute('id', oSettings.sTableId + '_' + paginateClassName)
        nFirst.setAttribute('id', oSettings.sTableId + '_' + firstClassName)
        nPrevious.setAttribute('id', oSettings.sTableId + '_' + previousClassName)
        nNext.setAttribute('id', oSettings.sTableId + '_' + nextClassName)
        nLast.setAttribute('id', oSettings.sTableId + '_' + lastClassName)
      }

      nInput.type = 'text'

      info = info.replace(/_INPUT_/g, '</span>' + nInput.outerHTML + '<span>')
      info = info.replace(/_TOTAL_/g, '</span>' + nTotal.outerHTML + '<span>')
      nInfo.innerHTML = '<span>' + info + '</span>'

      pagList.className = 'pagination mb-2'
      pagList.appendChild(nFirst)
      pagList.appendChild(nPrevious)
      pagList.appendChild(nNext)
      pagList.appendChild(nLast)
      nPaging.appendChild(pagList)

      $(nInfo).children().each(function (i, n) {
        nPaging.appendChild(n)
      })

      $(nFirst).click(function (e) {
        e.preventDefault()
        const iCurrentPage = calcCurrentPage(oSettings)
        if (iCurrentPage !== 1) {
          oSettings.oApi._fnPageChange(oSettings, 'first')
          fnCallbackDraw(oSettings)
        }
      })

      $(nPrevious).click(function (e) {
        e.preventDefault()
        const iCurrentPage = calcCurrentPage(oSettings)
        if (iCurrentPage !== 1) {
          oSettings.oApi._fnPageChange(oSettings, 'previous')
          fnCallbackDraw(oSettings)
        }
      })

      $(nNext).click(function (e) {
        e.preventDefault()
        const iCurrentPage = calcCurrentPage(oSettings)
        if (iCurrentPage !== calcPages(oSettings)) {
          oSettings.oApi._fnPageChange(oSettings, 'next')
          fnCallbackDraw(oSettings)
        }
      })

      $(nLast).click(function (e) {
        e.preventDefault()
        const iCurrentPage = calcCurrentPage(oSettings)
        if (iCurrentPage !== calcPages(oSettings)) {
          oSettings.oApi._fnPageChange(oSettings, 'last')
          fnCallbackDraw(oSettings)
        }
      })

      $(nPaging).find('.' + paginateInputClassName).keyup(function (e) {
        // 38 = up arrow, 39 = right arrow
        if (e.which === 38 || e.which === 39) {
          this.value++
        }
        // 37 = left arrow, 40 = down arrow
        else if ((e.which === 37 || e.which === 40) && this.value > 1) {
          this.value--
        }

        if (this.value === '' || this.value.match(/[^0-9]/)) {
          /* Nothing entered or non-numeric character */
          this.value = this.value.replace(/[^\d]/g, '') // don't even allow anything but digits
          return
        }

        let iNewStart = oSettings._iDisplayLength * (this.value - 1)
        if (iNewStart < 0) {
          iNewStart = 0
        }
        if (iNewStart >= oSettings.fnRecordsDisplay()) {
          iNewStart = (Math.ceil((oSettings.fnRecordsDisplay()) / oSettings._iDisplayLength) - 1) * oSettings._iDisplayLength
        }

        oSettings._iDisplayStart = iNewStart
        oSettings.oInstance.trigger('page.dt', oSettings)
        fnCallbackDraw(oSettings)
      })

      // Take the brutal approach to cancelling text selection.
      $('span', nPaging).bind('mousedown', function () { return false })
      $('span', nPaging).bind('selectstart', function () { return false })

      // If we can't page anyway, might as well not show it.
      const iPages = calcPages(oSettings)
      if (iPages <= 1) {
        $(nPaging).hide()
      }
    },

    fnUpdate: function (oSettings) {
      if (!oSettings.aanFeatures.p) {
        return
      }

      const iPages = calcPages(oSettings)
      const iCurrentPage = calcCurrentPage(oSettings)

      const an = oSettings.aanFeatures.p
      if (iPages <= 1) // hide paging when we can't page
      {
        $(an).hide()
        return
      }

      const disableClasses = calcDisableClasses(oSettings)

      $(an).show()

      // Enable/Disable `first` button.
      $('ul', an).children('.' + firstClassName)
        .removeClass(oSettings.oClasses.sPageButtonDisabled)
        .addClass(disableClasses[firstClassName])

      // Enable/Disable `prev` button.
      $('ul', an).children('.' + previousClassName)
        .removeClass(oSettings.oClasses.sPageButtonDisabled)
        .addClass(disableClasses[previousClassName])

      // Enable/Disable `next` button.
      $('ul', an).children('.' + nextClassName)
        .removeClass(oSettings.oClasses.sPageButtonDisabled)
        .addClass(disableClasses[nextClassName])

      // Enable/Disable `last` button.
      $('ul', an).children('.' + lastClassName)
        .removeClass(oSettings.oClasses.sPageButtonDisabled)
        .addClass(disableClasses[lastClassName])

      // Paginate of N pages text
      $(an).find('.' + paginateTotalClassName).html(iPages)

      // Current page number input value
      $(an).find('.' + paginateInputClassName).val(iCurrentPage)
    }
  }
})(jQuery)
