import TomSelect from 'tom-select'

const sync = (selectEl, tomSelect) => {
  selectEl.disabled ? tomSelect.disable() : tomSelect.enable()
  tomSelect.sync()
}

export function useTomselect (component, selectEl, opts = {}) {
  if (selectEl.classList.contains('tomselected')) {
    return
  }
  const tomSelect = new TomSelect(selectEl, opts)
  selectEl.addEventListener('change', () => sync(selectEl, tomSelect))
  return tomSelect
}
