import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['control', 'longText', 'shortText']

    showMore () {
      this.shortTextTarget.classList.toggle('d-none', true)
      this.longTextTarget.classList.toggle('d-none', false)
      this.controlTarget.textContent = 'Show less'
    }

    showLess () {
      this.shortTextTarget.classList.toggle('d-none', false)
      this.longTextTarget.classList.toggle('d-none', true)
      this.controlTarget.textContent = 'Show more'
    }

    toggle () {
      this.longTextTarget.classList.contains('d-none') ? this.showMore() : this.showLess()
    }
}
