import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = ['modal', 'frame']
  static values = {openOnStart: Boolean}

  modal = null
  permUrl = null

  connect () {
    this.onSubmitEnd = this.onSubmitEnd.bind(this)
    document.addEventListener('turbo:submit-end', this.onSubmitEnd)
    this.element.addEventListener('shown.bs.modal', () => this.onModalShown())
    this.element.addEventListener('hidden.bs.modal', () => this.onModalHidden())
    this.permUrl = this.frameTarget.src
    if (this.openOnStartValue) {
      this.openModal()
    }
  }

  disconnect () {
    document.removeEventListener('turbo:submit-end', this.onSubmitEnd)
  }

  onModalShown () {
    if (this.frameTarget.querySelector('[autofocus]')) {
      this.frameTarget.querySelector('[autofocus]').focus()
    }
  }

  onModalHidden () {
    if (!this.permUrl) {
      this.frameTarget.innerHTML = 'Loading..'
    } else {
      this.frameTarget.reload()
    }
  }

  openModal (event=null) {
    if (event) {
      event.preventDefault()
      if (!this.permUrl) {
        const url = event.currentTarget.href
        if (this.frameTarget.src === url) {
          this.frameTarget.reload()
        } else {
          this.frameTarget.src = url
        }
      }
    }
    this.modal = this.modal || new Modal(this.modalTarget)
    this.modal.show()
  }

  cancel (event) {
    event.preventDefault()
    if (!this.modal || !this.modal._isShown) {
      return
    }
    this.modal.hide()
  }

  onSubmitEnd (event) {
    if (!this.modal || !this.modal._isShown) {
      return
    }
    if (event.detail.success) {
      this.modal.hide()
      const fetchResponse = event.detail.fetchResponse
      const redirectLocation = fetchResponse.response.headers.get(
        'Turbo-Location')
      if (!redirectLocation) {
        if (this.permUrl) {
          this.frameTarget.reload()
        } else {
          this.frameTarget.src = null
        }
      }
    }
  }
}
