import { Modal, Tooltip } from 'bootstrap'
import { Turbo } from '@hotwired/turbo-rails'

export const reenableSubmitButtons = () => {
  document.querySelectorAll('.turbo-submit-disabled').forEach((button) => {
    button.toggleAttribute('disabled', false)
    button.classList.remove('turbo-submit-disabled')
  })
}

const TurboHelper = class {
  constructor () {
    document.addEventListener('turbo:load', function () {
      document.querySelectorAll('[data-bs-toggle="tooltip"]').forEach(el => {
        Tooltip.getOrCreateInstance(el)
      })
    })

    document.addEventListener('turbo:before-cache', () => {
      this.closeModal()
      reenableSubmitButtons()
    })

    document.addEventListener('turbo:submit-start', (event) => {
      const submitter = event.detail.formSubmission.submitter
      if (submitter) {
        submitter.toggleAttribute('disabled', true)
        submitter.classList.add('turbo-submit-disabled')
      }
    })

    document.addEventListener('turbo:before-fetch-request', (event) => {
      this.beforeFetchRequest(event)
    })

    document.addEventListener('turbo:before-fetch-response', (event) => {
      this.beforeFetchResponse(event)
    })
  }

  closeModal () {
    if (document.body.classList.contains('modal-open')) {
      const modalEl = document.querySelector('.modal.show')
      if (modalEl) {
        const modal = Modal.getInstance(modalEl)
        modalEl.classList.remove('fade')
        modal._backdrop._config.isAnimated = false
        modal.hide()
        modal.dispose()
      }
    }
  }

  beforeFetchRequest (event) {
    const frameId = event.detail.fetchOptions.headers['Turbo-Frame']
    if (!frameId) {
      return
    }

    const frame = document.querySelector(`#${frameId}`)

    if (frame && frame.dataset.turboFormRedirect) {
      event.detail.fetchOptions.headers['Turbo-Frame-Redirect'] = 1
    }
  }

  beforeFetchResponse (event) {
    const fetchResponse = event.detail.fetchResponse
    const redirectLocation = fetchResponse.response.headers.get(
      'Turbo-Location')
    if (!redirectLocation) {
      return
    }

    event.preventDefault()
    Turbo.clearCache()
    Turbo.visit(redirectLocation)
  }
}

export default new TurboHelper()
