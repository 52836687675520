function addTargetingClass(target) {
  target.classList.add('target-highlight');
}

function removeTargetingClass(target) {
  target.classList.remove('target-highlight');
}

export const useHighlight = controller => {
  Object.assign(controller, {
    highlight(target, timeout = 3000) {
      addTargetingClass(target);

      setTimeout(function () {
        removeTargetingClass(target);
      }, timeout);
    }
  });
};