import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["target", "template"];
  static values = {
    wrapperSelector: {
      type: String,
      default: ".nested-form-wrapper",
    },
  };

  add(e) {
    e.preventDefault();
    const uniqueId = this.generateUniqueId();
    let content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime().toString());
    content = content.replace(/UNIQUE_ID/g, uniqueId);
    this.targetTarget.insertAdjacentHTML("beforebegin", content);
    const event = new CustomEvent("rails-nested-form:add", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  remove(e) {
    e.preventDefault();
    const wrapper = e.target.closest(this.wrapperSelectorValue);
    if (wrapper.dataset.newRecord === "true") {
      wrapper.remove();
    } else {
      wrapper.style.display = "none";
      const input = wrapper.querySelector("input[name*='_destroy']");
      input.value = "1";
    }
    const event = new CustomEvent("rails-nested-form:remove", { bubbles: true });
    this.element.dispatchEvent(event);
  }

  generateUniqueId() {
    return Math.random().toString(36).substr(2, 9);
  }
}