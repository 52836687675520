import { Controller } from '@hotwired/stimulus'
import { useTomselect } from './mixins/use_tomselect'

export default class extends Controller {
  static values = {
    create: {type: Boolean, default: false}
  }
  connect () {
    useTomselect(this, this.element, {
      create: this.createValue,
      plugins: {
        clear_button: {
          title: 'Remove all selected options'
        }
      }
    })
  }
}
