import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['coPi', 'isMultiInvestigator'];

  toggleIsMultiInvestigator(e) {
    const coPi = this.coPiTarget;
    const isMultiInvestigator = this.isMultiInvestigatorTarget;
    if (coPi.value) {
      isMultiInvestigator.innerText = "Yes";
    } else {
      isMultiInvestigator.innerText = "No";
    }
  }
}