import { Controller } from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['input', 'hideable']

  connect () {
    this.updateVisibility()
  }

  updateVisibility () {
    const inputName = this.inputTargets[0].name
    let inputValue
    if (this.inputTargets[0].type === 'radio') {
      const checked = this.element.querySelector(`input[name="${inputName}"]:checked`)
      inputValue = checked && checked.value
    } else if (this.inputTargets[0].type === 'checkbox') {
      inputValue = this.inputTargets[0].checked && this.inputTargets[0].value
    } else {
      inputValue = this.inputTargets[0].value
    }

    this.hideableTargets.forEach(hideable => {
      if (typeof hideable.dataset.showIfEquals === 'undefined') {return}
      if (hideable.dataset.showIfEquals === inputValue) {
        hideable.hidden = false
      } else {
        hideable.hidden = true
        hideable.querySelectorAll('input').forEach(el => {
          if (AutoNumeric.isManagedByAutoNumeric(el)) {
            AutoNumeric.getAutoNumericElement(el).clear()
          } else {
            el.value = ''
          }
          const event = new Event('change')
          el.dispatchEvent(event)
        })
        hideable.querySelectorAll('select').forEach(el => {
          el.selectedIndex = 0
          const event = new Event('change')
          el.dispatchEvent(event)
        })
      }
    })
  }
}