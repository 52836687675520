import { Controller } from '@hotwired/stimulus'
import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = ['modal', 'formFrame']
  static values = { url: String }

  previousInnerHTML = false
  modal = null
  isOpen = false

  connect () {
    this.element.addEventListener('hidden.bs.modal', e => {
      this.isOpen = false

    })
  }

  open () {
    if (!this.isOpen) {
      this.isOpen = true
      this.previousInnerHTML = this.element.innerHTML
      if (this.hasModalTarget) {
        this.modal = this.modal || new Modal(this.modalTarget)
        this.modal.show()
      }
      this.formFrameTarget.src = this.urlValue
    }
  }

  reset (event) {
    if (event.detail.success) {
      if (this.modal) {
        this.modal.hide()
      }
      this.isOpen = false
      this.formFrameTarget.src = null
    }
  }

  cancel (event) {
    if (this.isOpen) {
      event.preventDefault()
      if (this.modal) {
        this.modal.hide()
      }
      this.isOpen = false
      this.element.innerHTML = this.previousInnerHTML
    }
  }
}
