import {Controller} from "@hotwired/stimulus"
import * as d3 from "d3"

export default class extends Controller {
    static targets = ['canvas', 'searchForm', 'options']

    loadData() {
        const url = '/publications?' + $(this.searchFormTarget).serialize() + "&chord=true"
        return fetch(url, {
                method: 'GET',
                headers: {
                    'Accept': 'application/json',
                }
            }
        ).then(result => result.json())
            .then(data => {
                this.pubData = data
            })
    }

    chord() {
        var modal = new bootstrap.Modal(document.getElementById('chord-modal'), {keyboard: false});
        modal.show();
        $(this.canvasTarget).html('<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span>')
        this.loadData().then(() => {
            this.drawChord()
        })
    }

    drawChord() {
        const names = this.pubData.all_authors;
        const indexByNames = new Map(names.map((name, i) => [name.join('.'), i]));
        var programs = Array.from(d3.union(names.map(name => name[0])));
        const indexByPrograms = new Map(programs.map((program, i) => [program, i]))
        if (programs.every(p => p.startsWith("TRIST-"))) {
            programs = programs.map(p => p.replace('TRIST-', ''))
        }
        const matrix = Array.from(indexByNames, () => new Array(names.length).fill(0));
        const matrixIntraOnly = Array.from(indexByNames, () => new Array(names.length).fill(0));
        const programStats = Array.from(indexByPrograms, () => ({nPubs: 0, nIntraPubs: 0, nInterPubs: 0}));

        const info = Array(names.length).fill().map(() => Array(names.length).fill().map(() => []));
        this.pubData.data.forEach(({pmid, title, date, authors}) => {
            const uniqAuthors = [...new Set(authors.map(a => a[1]))];
            const interPrograms = {}
            const intraPrograms = {}
            authors.forEach(name1 => {
                const i = indexByNames.get(name1.join('.'));
                authors.forEach(name2 => {
                    if (name1[1] !== name2[1]) {
                        const j = indexByNames.get(name2.join('.'));
                        if (name1[0] === name2[0]) {
                            intraPrograms[name1[0]] = true
                            matrixIntraOnly[i][j]++;
                            matrix[i][j]++;
                        } else {
                            interPrograms[name1[0]] = true
                            interPrograms[name2[0]] = true
                            matrix[i][j] = matrix[i][j] + 2; // to make them stand out
                        }

                        info[i][j].push(`<small class="text-muted">${date}</small> </small><small class='text-info'>PMID: ${pmid}</small><p>${title}</p><p class="fw-light">${uniqAuthors.join(', ')}</p>`);
                    }
                })
            })
            const uniqPrograms = [...new Set(authors.map(a => a[0]))];
            uniqPrograms.forEach(program => {
                programStats[indexByPrograms.get(program)].nPubs++;
                if (interPrograms[program]) {
                    programStats[indexByPrograms.get(program)].nInterPubs++;
                }
                if (intraPrograms[program]) {
                    programStats[indexByPrograms.get(program)].nIntraPubs++;
                }
            })
        })
        const groupBy = this.optionsTarget.querySelector('input[name="chordGroupBy"]:checked').value;
        const showInter = this.optionsTarget.querySelector('input[name="showInter"]').checked;

        this.canvasTarget.innerHTML = "";
        const width = 928;
        const height = width;
        const outerRadius = Math.min(width, height) * 0.5 - 80;
        const innerRadius = outerRadius - 20;

        const chord = d3.chord()
            .padAngle(2 / innerRadius)
            .sortSubgroups(d3.descending);

        const arc = d3.arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius);

        const ribbon = d3.ribbon()
            .radius(innerRadius);

        var tip = d3.select('body').append("div")
            .attr("class", "chord-tooltip")
            .style("opacity", 0)

        const svg = d3.create("svg")
            .attr("width", width)
            .attr("height", height)
            .attr("viewBox", [-width / 2, -height / 2, width, height])
            .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;");

        const chords = chord(showInter ? matrix : matrixIntraOnly);

        const group = svg.append("g")
            .selectAll()
            .data(chords.groups)
            .join("g");

        group.append("path")
            .attr("fill", d => d3.schemeCategory10[indexByPrograms.get(names[d.index][0])])
            .attr("d", arc)

        svg.append("g")
            .selectAll()
            .data(chords)
            .join("path")
            .attr("d", ribbon)
            .attr("fill-opacity", d => {
                if (names[d.source.index][0] === names[d.target.index][0]) {
                    return 0.7
                } else {
                    return 1
                }
            })
            .attr("fill", d => {
                if (names[d.source.index][0] === names[d.target.index][0]) {
                    return d3.schemeCategory10[indexByPrograms.get(names[d.source.index][0])]
                } else {
                    return "black"
                }
            })
            .on('mouseenter', function (event, d) {
                var i = d.source.index
                var j = d.target.index
                svg.selectAll("g.chord path").filter(d => d.source.index !== i || d.target.index !== j).transition().style("opacity", .1);
                d3.select(this).style("cursor", "pointer").style("opacity", 1)
                tip.style('opacity', 1).html(`<p>${info[i][j].length} collaborations between <strong>${names[i][1]}</strong> (<span class="text-muted">${names[i][0]}</span>) and <strong>${names[j][1]}</strong> (<span class="text-muted">${names[j][0]}</span>)</p>`)
                    .style("left", event.pageX + "px")
                    .style("top", event.pageY + "px");
            }).on("click", (event, d) => {
            var i = d.source.index;
            var j = d.target.index;
            $('#chord-detail').modal("show");
            $("#chord-detail-body").html(`<p>${info[i][j].length} collaborations between <strong>${names[i][1]}</strong> (<span class="text-muted">${names[i][0]}</span>) and <strong>${names[j][1]}</strong> (<span class="text-muted">${names[j][0]}</span>)</p> <ol>${info[i][j].map(pubInfo => `<li>${pubInfo}</li>`).join(' ')}</ol>`);
        }).on('mouseleave', (event, d) => {
            svg.selectAll("g.chord path").transition().style("cursor", "none").style("opacity", 1);
            tip.style("opacity", 0)
        })

        if (groupBy === 'member') {
            group.append("text")
                .each(d => (d.angle = (d.startAngle + d.endAngle) / 2))
                .attr("dy", "0.35em")
                .attr("transform", d => `rotate(${(d.angle * 180 / Math.PI - 90)}) translate(${outerRadius + 5})${d.angle > Math.PI ? "rotate(180)" : ""}`)
                .attr("text-anchor", d => d.angle > Math.PI ? "end" : null)
                .text(d => names[d.index][1]);
        } else if (groupBy === 'program') {
            const programIdByIndex = d => indexByPrograms.get(names[d.index][0]);
            const programAngles = chords.groups.reduce((result, d) => {
                result[programIdByIndex(d)] = result[programIdByIndex(d)] || {
                    startAngle: d.startAngle,
                    endAngle: d.endAngle
                };
                if (d.startAngle < result[programIdByIndex(d)].startAngle) {
                    result[programIdByIndex(d)].startAngle = d.startAngle;
                }
                if (d.endAngle > result[programIdByIndex(d)].endAngle) {
                    result[programIdByIndex(d)].endAngle = d.endAngle;
                }
                return result;
            }, {});
            const programGroupsData = Object.keys(programAngles).map(programId => (
                {
                    programId: programId,
                    startAngle: programAngles[programId].startAngle,
                    endAngle: programAngles[programId].endAngle
                }
            ));
            const programGroup = svg.append("g")
                .selectAll()
                .data(programGroupsData)
                .join("g");

            const outerLabelArc = d3.arc()
                .innerRadius(outerRadius + 35)
                .outerRadius(outerRadius + 55);
            programGroup.append("text")
                .attr('class', 'label')
                .each(d => (d.angle = (d.startAngle + d.endAngle) / 2))
                .attr("font-size", 30)
                .style('fill', d => d3.schemeCategory10[d.programId])
                .attr("transform", d => `translate(${outerLabelArc.centroid(d)})`)
                .attr("text-anchor", d => "middle")
                .call(text => text.append("tspan")
                    .attr("font-weight", "bold")
                    .text(d => programs[d.programId]))
                .call(text => text.append("tspan")
                    .attr("x", 0)
                    .attr("dy", "1em")
                    .attr("fill-opacity", 0.7)
                    .text(d => `${programStats[d.programId].nPubs}`));
            if (!showInter) {
                const innerLabelArc = d3.arc()
                    .innerRadius(0)
                    .outerRadius(innerRadius * 0.7);
                programGroup.append("text")
                    .attr('class', 'label')
                    .each(d => (d.angle = (d.startAngle + d.endAngle) / 2))
                    .attr("font-size", 25)
                    .style('fill', d => d3.schemeCategory10[d.programId])
                    .attr("transform", d => `translate(${innerLabelArc.centroid(d)})`)
                    .append("tspan")
                        .attr("text-anchor", "middle")
                        .text(d => `${programStats[d.programId].nIntraPubs}`);
            }
            var deltaX, deltaY;

            var dragChildren = d3.drag()
                .on("start", function (e) {
                    var child = d3.select(this).selectChild();
                    deltaX = child.attr("x") - e.x;
                    deltaY = child.attr("y") - e.y;
                }).on("drag", function (e) {
                    d3.select(this)
                        .selectChildren()
                        .each(function () {
                            d3.select(this).attr("x", e.x + deltaX);
                            d3.select(this).attr("y", e.y + deltaY);
                        });
                });
            dragChildren(svg.selectAll("text.label").style("cursor", "pointer"))
            dragChildren(svg.selectAll("text.label").style("cursor", "pointer"))
        }

        this.canvasTarget.append(svg.node());
    }
}