import {Controller} from "@hotwired/stimulus"

const statusToIcons = {
  'all': 'check',
  'some': 'minus',
  'none': null
};

export default class extends Controller {
  static targets = ["tag"]

  connect() {
    this.itemIds = JSON.parse(this.element.dataset.itemIds);
    this.itemType = this.element.dataset.itemType;
    this.tagTargets.forEach(tagEl => this.displayTagStatus(tagEl, tagEl.dataset.tagStatus));
    this.newTagStatuses = {};
  }

  setTagStatus(tagId, status) {
    const tagEl = this.tagTargets.find(t => t.dataset.tagId === tagId);
    tagEl.dataset.tagStatus = status;
    this.displayTagStatus(tagEl, status);
  }

  displayTagStatus(tagEl, status) {
    const icon = statusToIcons[status];
    tagEl.querySelector('.tag-status').innerHTML = icon ? `<i class="fas fa-${icon}"></i>` : '';
  }

  applyChanges(e) {
    e.preventDefault();
    const data = {item_ids: this.itemIds, item_type: this.itemType, new_tag_statuses: this.newTagStatuses};
    const form = this.element.querySelector('form');
    const formData = new FormData(form);
    formData.forEach((value, key) => data[key] = value);
    fetch(form.action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    }).then(results => location.reload());
  }

  changeTag(e) {
    e.preventDefault();
    const {tagId, tagStatus} = e.currentTarget.dataset;
    this.newTagStatuses[tagId] = (tagStatus === 'some' || tagStatus === 'none') ? 'all' : 'none'
    this.setTagStatus(tagId, this.newTagStatuses[tagId]);
  }
}