import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['multiPIEntry', 'isMultiPI']

  connect () {
    this.element.addEventListener('rails-nested-form:add', () => {
      this.updateIsMultiPI()
    })

    this.element.addEventListener('rails-nested-form:remove', () => {
      this.updateIsMultiPI()
    })
  }

  updateIsMultiPI () {
    const anyVisible = this.multiPIEntryTargets.some(entry => entry.offsetParent !== null)
    this.isMultiPITarget.innerText = anyVisible ? 'Yes' : 'No'
  }
}
