import {Controller} from "stimulus";

export default class extends Controller {
  static targets = ["option", "suggestedOption"];

  selectAll(event) {
    const actionType = event.currentTarget.getAttribute('data-value');
    this.optionTargets.forEach(el => {
      if (el.value === actionType) {
        el.checked = true;
      }
    });
  }

  selectSuggested(event) {
    this.suggestedOptionTargets.forEach(el => {
      el.checked = true;
    });
  }
}