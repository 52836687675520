$(function () {
  $('.standard-datatable').each(function () {
    if (!$.fn.DataTable.isDataTable(this)) {
      $(this).DataTable({
        paging: false
      })
    }
  })
  $('.minimal-datatable').each(function () {
    if (!$.fn.DataTable.isDataTable(this)) {
      $(this).DataTable({
        paging: false,
        ordering: false,
        searching: false,
        info: false
      })
    }
  })
  $(document).on('init.dt', function (e, settings) {
    const api = new $.fn.dataTable.Api(settings)
    api.table().on('user-select', function (e, dt, type, cell, originalEvent) {
      return originalEvent.target.tagName !== 'A'
    })
  })
})
