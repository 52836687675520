import CheckboxSelectAll from "stimulus-checkbox-select-all"

export default class extends CheckboxSelectAll {
  static values = {destroyMultipleUrl: String}

  destroySelected(event) {
    event.preventDefault();
    if (!window.confirm("Are you sure?")) {
      return null;
    }
    event.target.disabled = true;
    const data = new FormData();
    this.checked.forEach((checkbox) => data.append("ids[]", checkbox.value));
    const token = document.querySelector('meta[name="csrf-token"]').content;
    fetch(this.destroyMultipleUrlValue, {
      method: "DELETE",
      headers: {
        "X-CSRF-Token": token,
      },
      body: data,
    }).then((response) => {
      if (response.redirected) {
        window.location.href = response.url;
      }
    }).then(data => {
      event.target.disabled = false;
    });
  }
}

