import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
  static values = {hideText: String, tableSelector: String, columnName: String}

  hidden = false
  dt = null
  columnIndex = null

  filterFn = (settings, rowValues) => {
    if (this.columnIndex !== null) {
      return !rowValues[this.columnIndex].includes(this.hideTextValue)
    } else {
      return !rowValues.includes(this.hideTextValue)
    }
  }

  hideRows() {
    this.applyFilter();
    this.hidden = true;
    this.dt.draw();
    this.element.textContent = 'Show ' + this.hideTextValue;
  }

  showAllRows() {
    this.removeFilter();
    this.hidden = false;
    this.dt.draw();
    this.element.textContent = 'Hide ' + this.hideTextValue;
  }

  connect() {
    this.dt = $(this.tableSelectorValue).DataTable();
    if (this.columnNameValue) {
      this.columnIndex = this.dt.column(`${this.columnNameValue}:name`).index();
    }
    this.hideRows();
  }

  disconnect() {
    $.fn.dataTable.ext.search.pop();
  }

  toggle(e) {
    e.preventDefault();
    this.hidden ? this.showAllRows() : this.hideRows();
  }

  applyFilter() {
    $.fn.dataTable.ext.search.push(this.filterFn);
  }

  removeFilter() {
    $.fn.dataTable.ext.search = $.fn.dataTable.ext.search.filter(e => e !== this.filterFn)
  }
}