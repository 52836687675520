import {Controller} from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
    static targets = ['amount', 'percent', 'reportSharedAmount', 'totalAmount', 'totalAmountDisplay']
    static values = {'totalAmount': Number, 'roundToTwoDecimalPlaces': Boolean}

    connect() {
        this.nAmountsInitialized = 0
        this.element.addEventListener('autoNumeric:initialized', e => {
            this.nAmountsInitialized++
            if (this.nAmountsInitialized === this.amountTargets.length + 1) {
                this.totalAmountValue = AutoNumeric.getNumber(this.totalAmountTarget)
                this.updatePercents()
            }
        })
    }

    useSharedAmount(elem) {
        if (!elem.closest('.program-container')) {
            return
        }
        const reportSharedAmountEl = elem.closest('.program-container').querySelector('[data-award-amount-target=\'reportSharedAmount\']')
        return reportSharedAmountEl ? reportSharedAmountEl.checked : false
    }

    onAmountChange(e) {
        this.updatePercents()
    }

    onPercentChange(e) {
        this.updateAmountByPercent(e.target)
    }

    onReportSharedAmountChange(e) {
        const programContainer = e.target.closest('.program-container')
        if (e.target.checked) {
            AutoNumeric.getAutoNumericElement(programContainer.querySelector('[data-award-amount-target=\'amount\']')).clear()
            programContainer.querySelector('[data-award-amount-target=\'percent\']').value = null
            programContainer.querySelector('.amount-container').style.display = 'none'
            programContainer.querySelector('.report-shared-amount-dependent').style.display = 'block'
        } else {
            programContainer.querySelector('.amount-container').style.display = 'block'
            programContainer.querySelector('.report-shared-amount-dependent').style.display = 'none'
        }
    }

    onTotalAmountChange() {
        this.totalAmountValue = AutoNumeric.getNumber(this.totalAmountTarget)
        this.percentTargets.forEach(percentEl => this.updateAmountByPercent(percentEl))
    }

    totalAmountValueChanged() {
        const formattedTotal = AutoNumeric.format(this.totalAmountValue)
        this.totalAmountDisplayTargets.forEach(el => el.innerText = `$${formattedTotal}`)
    }

    updateAmountByPercent(percentEl) {
        if (this.useSharedAmount(percentEl)) {
            return
        }
        const percentValue = percentEl.value
        if (percentValue && this.totalAmountValue) {
            const amountValue = this.totalAmountValue * percentValue / 100
            const amountValueRounded = this.roundToTwoDecimalPlacesValue ? Math.round(amountValue * 100) / 100 : Math.round(amountValue)
            AutoNumeric.set(percentEl.closest(".amount-container").querySelector("[data-award-amount-target='amount']"), amountValueRounded)
            this.dispatch("partialAmountUpdated")
        }
    }

    updatePercents() {
        if (!this.totalAmountValue) {
            return
        }
        this.amountTargets.forEach(amountEl => {
            if (this.useSharedAmount(amountEl)) {
                return
            }
            const amountValue = AutoNumeric.getNumber(amountEl)
            if (amountValue) {
                const percentEl = amountEl.closest('.amount-container').querySelector("[data-award-amount-target='percent']")
                percentEl.value = Math.round(amountValue / this.totalAmountValue * 100)
            }
        })
    }
}
