import { Controller } from '@hotwired/stimulus'
import { useTomselect } from './mixins/use_tomselect'

const personRenderer = (item, escape) => {
  const inSystemIndicator = item.inSystem
    ? '<span class="badge bg-success ms-2">In System</span>'
    : '<span class="badge bg-secondary ms-2">New</span>'

  return `
<div class="py-2 hstack gap-2">
  <div class="mb-1">
    <span class="fw-bold">
      ${escape(item.firstName)}
      ${escape(item.lastName)}
    </span>
    ${inSystemIndicator}
  </div>
  <div>NetID: ${escape(item.netid)}</div>
  <div>Email: ${escape(item.email)}</div>
  <div>${escape(item.title)}</div>
</div>`
}

export default class extends Controller {
  static values = { placeholder: String }

  connect() {
    this.tomSelect = useTomselect(this, this.element, {
      plugins: {
        remove_button: {
          title: 'Remove this item',
        }
      },
      placeholder: this.placeholderValue || 'Type full name, NetID or email to search NU people directory',
      valueField: 'netid',
      labelField: 'fullName',
      searchField: ['fullName', 'netid', 'email'],
      load: (query, callback) => {
        const url = '/directory_searches/search?search[query]=' + encodeURIComponent(query)
        fetch(url, {
          headers: {
            'Accept': 'application/json'
          }
        })
          .then(response => response.json())
          .then(json => {
            if (json.error) {
              callback([], { message: json.error })
            } else {
              const result = json.items.map(p => ({
                firstName: p.first_name,
                lastName: p.last_name,
                title: p.title,
                fullName: p.full_name,
                netid: p.netid,
                email: p.email,
                inSystem: p.in_system
              }))
              callback(result)
            }
          }).catch((error) => {
          console.error('Error:', error)
          callback([], { message: 'An unexpected error occurred. Please try again later.' })
        })
      },
      render: {
        option: personRenderer,
        item: personRenderer,
        no_results: (data, escape) => {
          return '<div class="no-results">No results found</div>';
        },
        loading: (data, escape) => {
          return '<div class="spinner-border spinner-border-sm" role="status"><span class="visually-hidden">Loading...</span></div>';
        },
      },
      onInitialize: function() {
        // Add a custom CSS class to the wrapper for styling
        this.wrapper.classList.add('person-search-wrapper');
      }
    })
  }
}