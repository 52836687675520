import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    if (!this.element.dataset.datepickerInitiated) {
      $(this.element).datepicker({
        format: 'yyyy-mm-dd',
        todayBtn: 'linked',
        clearBtn: true,
        autoclose: true,
        todayHighlight: true
      })
      this.element.dataset.datepickerInitiated = true
    }
  }
}
