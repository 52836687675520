import { Controller } from '@hotwired/stimulus'
import { Tooltip } from 'bootstrap'

export default class extends Controller {
  static values = {
    showOnLoad: Boolean
  }
  connect () {
    this.tooltip = new Tooltip(this.element)
    if (this.showOnLoadValue) {
      this.tooltip.show()
    }
  }
}
