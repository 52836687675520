import {Controller} from '@hotwired/stimulus'
import AutoNumeric from 'autonumeric'

export default class extends Controller {
  static targets = ['earnedAmount', 'projectTotal', 'projectDirect']
  static values = {'directEstimatePercentage': Number}

  onEarnedAmountChange(e) {
    const earnedAmount = AutoNumeric.getNumber(e.currentTarget)
    AutoNumeric.set(this.projectDirectTarget, earnedAmount)
    const totalAmount = Math.round(earnedAmount * (1 + this.directEstimatePercentageValue / 100) * 100) / 100
    AutoNumeric.set(this.projectTotalTarget, totalAmount)
    this.projectDirectTarget.dispatchEvent(new Event('change'));
    this.projectTotalTarget.dispatchEvent(new Event('change'));
  }
}