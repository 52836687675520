import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["radio", "internalSection", "externalSection"]

  connect() {
    this.toggleVisibility()
  }

  toggleVisibility() {
    const isInternal = this.radioTargets.find(radio => radio.checked).value === "internal"

    this.toggleSection(this.internalSectionTarget, isInternal)
    this.toggleSection(this.externalSectionTarget, !isInternal)
  }

  toggleSection(section, isActive) {
    section.classList.toggle('opacity-50', !isActive)
    section.classList.toggle('pe-none', !isActive)

    section.querySelectorAll('input, select').forEach(input => {
      input.classList.toggle('bg-light', !isActive)
    })

    if (!isActive) {
      this.clearSection(section)
    }
  }

  clearSection(section) {
    section.querySelectorAll('input, select').forEach(input => {
      if (input.type === 'radio') {
        input.checked = false
      } else if (input.tagName === 'SELECT' && input.tomselect) {
        input.tomselect.clear()
      } else {
        input.value = ''
      }
    })
  }
}