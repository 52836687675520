import {Controller} from "@hotwired/stimulus"
import Rails from '@rails/ujs'

export default class extends Controller {
  static targets = ["form", "field", "fieldWrapper", "request", "messagesContainer"]

  connect() {
    const urlParams = new URLSearchParams(window.location.search);
    const messageId = urlParams.get('message_id');
    if (messageId) {
      this.showMessages();
    }
  }

  submitForm() {
    $(this.formTarget).find('button[name="submit"]').tooltip('hide');
    Rails.fire(this.formTarget, 'submit');
  }

  showMessages() {
    $(this.messagesContainerTarget).collapse('show');
  }

  getFieldValue(inputs) {
    if (inputs[0].type === "radio") {
      const checked = inputs.find(input => input.checked);
      return checked ? checked.value : undefined;
    } else {
      return inputs[0].value;
    }
  }

  setFieldValue(inputs, value) {
    if (inputs[0].type === "radio") {
      inputs.forEach(input => {
        input.checked = (input.value === value);
        const event = new Event('change');
        input.dispatchEvent(event);
      });
    } else {
      inputs[0].value = value;
      const event = new Event('change');
      inputs[0].dispatchEvent(event);
    }
  }

  markProcessed(requestEl) {
    requestEl.classList.remove("list-group-item-info");
    requestEl.classList.add("list-group-item-secondary");
    requestEl.querySelector(".not-reviewed").style.display = "none";
    requestEl.querySelector(".reviewed").style.display = "block";
  }

  unmarkProcessed(event) {
    event.preventDefault();
    const requestEl = event.target.closest("[data-publication-group-review-target='request']");
    if (this.fieldWrapperTarget.dataset.origValue !== undefined) {
      this.setFieldValue(this.fieldTargets, this.fieldWrapperTarget.dataset.origValue);
    }
    requestEl.classList.remove("list-group-item-secondary");
    requestEl.classList.add("list-group-item-info");
    requestEl.querySelector(".not-reviewed").style.display = "block";
    requestEl.querySelector(".reviewed").style.display = "none";
  }

  highlightField() {
    this.fieldWrapperTarget.style.outline = "grey dashed 1px";
  }

  deHighlightField() {
    this.fieldWrapperTarget.style.outline = "";
  }

  highlightReviewerSelection(event) {
    const requestEl = event.target.closest("[data-publication-group-review-target='request']");
    this.fieldTargets.forEach(fieldEl => {
      if (fieldEl.type === "radio" && fieldEl.value === requestEl.dataset.requestValue) {
        fieldEl.parentElement.classList.add("text-danger")
      }
    });
  }

  deHighlightReviewerSelection(event) {
    const requestEl = event.target.closest("[data-publication-group-review-target='request']");
    this.fieldTargets.forEach(fieldEl => {
      if (fieldEl.type === "radio" && fieldEl.value === requestEl.dataset.requestValue) {
        fieldEl.parentElement.classList.remove("text-danger")
      }
    });
  }

  apply(event) {
    event.preventDefault();
    const requestEl = event.target.closest("[data-publication-group-review-target='request']");
    this.fieldWrapperTarget.dataset.origValue = this.getFieldValue(this.fieldTargets);
    this.setFieldValue(this.fieldTargets, requestEl.dataset.requestValue)
    this.markProcessed(requestEl);
  }

  dismiss(event) {
    event.preventDefault();
    const requestEl = event.target.closest("[data-publication-group-review-target='request']");
    this.markProcessed(requestEl);
  }
}
