import { Controller } from '@hotwired/stimulus'
import TomSelect from 'tom-select'
import { useTargetMutation } from 'stimulus-use'

import { Modal } from 'bootstrap'

export default class extends Controller {
  static targets = ['select', 'newOption']
  static values = { populateField: String, frameId: String }

  bsModal = null
  tomSelect = null
  frameEl = null
  modalEl = null
  isCreating = false

  connect () {
    useTargetMutation(this, { targets: ['newOption'] })
    this.modalEl = this.modalEl ||
      document.getElementById(this.frameIdValue + '_modal')
    this.frameEl = this.frameEl || document.getElementById(this.frameIdValue)
    this.addParamToRequest = this.addParamToRequest.bind(this)
    this.frameEl.addEventListener('turbo:before-fetch-request',
      this.addParamToRequest)

    this.tomSelect = this.initTomSelect()
    this.initNewItemAnimation()
  }

  disconnect () {
    this.frameEl.removeEventListener('turbo:before-fetch-request',
      this.addParamToRequest)
  }

  addParamToRequest (e) {
    e.preventDefault()
    if (e.detail.fetchOptions.body && this.isCreating) {
      e.detail.fetchOptions.body.append('add_option_to', this.selectTarget.id)
    }
    e.detail.resume()
  }

  initNewItemAnimation () {
    const needHighlight = {}
    this.tomSelect.on('option_add', function (value, data) {
      needHighlight[value] = true
    })
    this.tomSelect.on('item_add', (value, item) => {
      if (needHighlight[value]) {
        delete needHighlight[value]
        item.classList.add('newly-created', 'highlight')
        window.setTimeout(() => {
          item.classList.remove('highlight')
        }, 500)
      }
    })
  }

  initTomSelect () {
    const fieldName = this.populateFieldValue

    this.modalEl.addEventListener('hide.bs.modal', () => {
      this.isCreating = false
      this.frameEl.reload()
    })

    return new TomSelect(this.selectTarget, {
      plugins: ['dropdown_input', 'remove_button'],
      placeholder: 'Type to search or create',
      create: (input, callback) => {
        this.isCreating = true
        this.bsModal = this.bsModal ||
          new Modal(this.modalEl, { keyboard: false })
        this.bsModal.show()
        if (this.frameEl.isActive) {
          const field = this.frameEl.querySelector(`form input[name$="[${fieldName}]"]`)
          field.value = input
          field.focus()
        }
        this.tomCreateCallback = callback
      },
    })
  }

  newOptionTargetAdded (element) {
    this.bsModal.hide()
    const value = element.value
    const text = element.innerText
    element.remove()
    this.tomCreateCallback({ value, text })
  }
}
