// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import * as ActiveStorage from '@rails/activestorage'
import 'channels'
import { Turbo } from '@hotwired/turbo-rails'
import * as bootstrap from 'bootstrap'

import 'datatables.net-bs5'
import 'datatables.net-fixedcolumns-bs5'
import 'datatables.net-fixedheader-bs5'
import '@nathanvda/cocoon'
import 'select2'
import 'select2-to-tree/src/select2totree'
import 'yadcf'
import 'moment'
import 'datatables.net-buttons/js/dataTables.buttons'
import 'datatables.net-buttons/js/buttons.colVis'
import 'datatables.net-buttons/js/buttons.html5'
import 'datatables.net-buttons/js/buttons.print'
import 'datatables.net-select/js/dataTables.select'
import 'datatables.net-responsive/js/dataTables.responsive'
import 'jquery-datatables-checkboxes/js/dataTables.checkboxes'
import 'jszip/dist/jszip'
import 'bootstrap-datepicker'
import 'datatables.net-responsive-bs5/js/responsive.bootstrap5'
import 'datatables.net-buttons-bs5/js/buttons.bootstrap5'
import 'datatables.net-select-bs5/js/select.bootstrap5'

import "controllers"
import 'src/turbo/turbo-helper'

window.bootstrap = bootstrap
Rails.start()
ActiveStorage.start()
Turbo.session.drive = false

require('../src/legacy/people')
require('../src/legacy/report')
require('../src/legacy/snapshots')
require('../src/legacy/speaker_events')
require('../src/legacy/user')
require('../src/legacy/datatables/pagination_input')
require('../src/legacy/app')
require('../src/legacy/datatables')
require('../src/legacy/dependency_manager')
require('../src/legacy/documents')
require('../src/legacy/group')
require('../src/legacy/group_applications')
require('../src/legacy/home')
