$(document).ready(function() {
  var member_type_string =
    'program_membership_application[program_membership_detail_attributes][desired_classification]';

  function dimProgramBox() {
    $('#possible_programs :input')
      .val(null)
      .trigger('change');
    $('#possible_programs :input').attr('disabled', true);
    $('#possible_programs').addClass('text-muted');
  }

  if (
    $(
      '#program_membership_application_program_membership_detail_attributes_desired_classification_associate_member'
    ).is(':checked')
  ) {
    dimProgramBox();
  }

  $('input[name="' + member_type_string + '"]').change(function() {
    if (this.value == 'Associate Member') {
      dimProgramBox();
    } else {
      $('#possible_programs :input').attr('disabled', false);
      $('#possible_programs').removeClass('text-muted');
    }
  });

  $(
    '#program_membership_application_program_membership_detail_attributes_desired_classification_full_member'
  )
    .parent()
    .popover({
      title: 'Full Member',
      content:
      'Clinicians and scientists holding a faculty appointment in the University; evidence of continuous, active, scholarly productivity in the form of cancer relevant publications, funded research, or leadership of investigator initiated trials is required; clear and sustained commitment to participate in the collaborative research, clinical and/or education programs of the Lurie Cancer Center, including cross-campus and inter-institutional activities is expected.',
      trigger: 'hover'
    });
$(
  '#program_membership_application_program_membership_detail_attributes_desired_classification_associate_member'
)
  .parent()
  .popover({
    title: 'Associate Member',
    content:
    'Clinicians and scientists holding a faculty appointment in the University or its affiliated institutions; demonstrated interest and participation in Lurie Cancer Center basic, translational, clinical, and population science research programs as well as other cancer-related programs and activities required; classification focuses predominately on faculty working in full member laboratories or other Lurie Cancer Center programs but who do not have independent research status.',
    trigger: 'hover'
  });
$(
  '#program_membership_application_program_membership_detail_attributes_desired_classification_affiliate_member'
)
  .parent()
  .popover({
    title: 'Affiliate Member',
    content:
    'Faculty or other members of the Northwestern Medical Group - including members of Regional Oncology Practices - whose primary role is the provision of clinical cancer care, including enrollment of patients onto clinical trials; key administrative personnel and faculty of other institutions holding adjunct appointments at Northwestern University may be considered for this membership status as well.',
    trigger: 'hover'
  });
});
