import AutoNumeric from 'autonumeric'

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { currencySymbol: String }
  autoNumeric = null

  connect() {
    this.autoNumeric = new AutoNumeric(this.element, { unformatOnSubmit: true, modifyValueOnWheel: false, currencySymbol: this.currencySymbolValue })
  }

  disconnect() {
    this.autoNumeric.remove()
  }
}
