import { Controller } from "@hotwired/stimulus"
import { useHighlight } from './mixins/use_highlight'
import { filterToPayload } from './mixins/form_data'

export default class extends Controller {
    static targets = ['table', 'searchForm']
    static values = {
        columns: Array,
        enableAdminFeatures: Boolean,
        memberReviewUrl: String,
        groupReviewUrl: String,
        newBulkReviewStatusUrl: String,
        bulkArchivePublicationUrl: String,
        editTagsUrl: String,
    }

    connect() {
        const self = this;
        useHighlight(this)
        this.element[this.identifier] = this;

        document.getElementById('has_pmid').addEventListener('change', (event) => {
            document.getElementById('pmid').disabled = event.target.value === 'No';
        });
        document.getElementById('has_pmcid').addEventListener('change', (event) => {
            document.getElementById('pmcid').disabled = event.target.value === 'No';
        });

        this.datatable = $(this.tableTarget).DataTable({
            ajax: {
                url: '/publications',
                dataType: 'json',
                data: d => $.extend({}, d, filterToPayload(this.searchFormTarget))
            },
            select: {
                style: "multi",
                selector: 'td:first-child,td:nth-child(2)',
            },
            columns: this.columnsValue,
            searching: false,
            processing: true,
            pagingType: "simple_numbers",
            language: {
                processing: '<i class="fa fa-spinner fa-spin fa-3x fa-fw"></i><span class="sr-only">Loading...</span>'
            },
            serverSide: true,
            ordering: true,
            order: [[this.columnsValue.findIndex(e => e.data === 'pub_date'), "desc"]],
            paginationType: "input",
            // responsive: true,
            lengthMenu: [[10, 25, 50, 100, 500, -1], [10, 25, 50, 100, 500, 'All']],
            stateSave: true,
            dom: 'Blfrtip',
            buttons: [{
                extend: 'selectAll',
                text: 'Select All In Current Page',
                className: 'btn btn-sm btn-outline-dark'
            }, { extend: 'selectNone', className: 'btn btn-sm btn-outline-dark' }
            ]
        });

        if (this.enableAdminFeaturesValue) {
            this.addAdminButtons();
            this.initTags();
        }
        this.searchFormTarget.addEventListener('submit', () => this.datatable.state.clear());
    }

    search(e) {
        e.preventDefault();
        this.datatable.ajax.reload();
    }

    clear(e) {
        e.preventDefault();
        this.searchFormTarget.clear();
    }

    selectedPublicationIds() {
        return this.datatable.rows({ selected: true }).data().pluck('publication_id').toArray();
    }

    openModalFn(url, modalId) {
        return () => {
            const selectedPubIds = this.selectedPublicationIds();
            if (selectedPubIds.length > 0) {
                $('#' + modalId + ' .modal-body').html("Loading...");
                $.ajax({ url: url, type: 'GET', data: { publication_ids: selectedPubIds } })
                    .done(function (response, status) {
                        $('#' + modalId + ' .modal-body').html(response);
                        $('#' + modalId).modal("show");
                    });
            }
        }
    }

    addAdminButtons() {
        const self = this;
        this.datatable.button().add(2, {
            text: 'Request Member Review', className: "btn btn-sm btn-outline-dark requires-selected",
            action: this.openModalFn(this.memberReviewUrlValue, 'requests-modal')
        });
        this.datatable.button().add(3, {
            text: 'Request Program Lead Review', className: "btn btn-sm btn-outline-dark requires-selected",
            action: this.openModalFn(this.groupReviewUrlValue, 'requests-modal')
        });
        this.datatable.button().add(4, {
            text: 'Mark As Admin/PL Confirmed', className: "btn btn-sm btn-outline-dark requires-selected",
            action: this.openModalFn(this.newBulkReviewStatusUrlValue, 'update-review-status-modal')
        });
        this.datatable.button().add(5, {
          text: "Archive Selected",
          className: "btn btn-sm btn-outline-dark requires-archivable",
          action: this.openModalFn(
            this.bulkArchivePublicationUrlValue,
            "bulk-archive-modal",
          ),
        });
        this.datatable.button().add(6, {
            text: 'Export',
            className: "btn btn-sm btn-outline-dark",
            action: function (e, dt, node, config) {
                const recordsFiltered = dt.page.info().recordsDisplay;
                if (recordsFiltered > 1000) {
                    const result = window.confirm("You are about to export " + recordsFiltered + " publications and it will take a long time! Are you sure? You can add filters to reduce the number of records.");
                    if (!result) {
                        return;
                    }
                }
                $('#export-modal').modal('show');
                $('#export-form input[type="submit"]').prop('disabled', false);
                $('#export-form').on("submit", function (e) {
                    $('#export-form input[type="submit"]').prop('disabled', true);
                    e.preventDefault();
                    const $searchForm = $(self.searchFormTarget);
                    $searchForm.attr('action', '/publications.xlsx');
                    $('#export-form').serializeArray().forEach(function (item) {
                        $("<input />").attr("type", "hidden").attr("name", item.name).attr("value", item.value).appendTo("#search-form");
                    });
                    $searchForm[0].submit();
                    $searchForm.attr('action', '/publications');
                    $('#export').serializeArray().forEach(function (item) {
                        $searchForm.find("input[name*='" + item.name + "']").remove();
                    });
                });
            }
        });
        // this.datatable.buttons(1, null).containers().prependTo('#tags-button-wrapper');
        this.toggleButtons();
        this.datatable.on('select deselect', () => this.toggleButtons);
    }

    initTags() {
        const self = this;
        new $.fn.dataTable.Buttons(this.datatable, {
            name: 'tags',
            className: 'dropdown',
            buttons: [{
                text: 'Edit Tags',
                className: "btn btn-sm btn-outline-dark requires-selected dropdown-toggle",
                attr: {
                    'data-bs-toggle': "dropdown",
                    id: 'edit-tags'
                },
                action: function (e, dt, node, config) {
                    const selectedPubIds = self.selectedPublicationIds();
                    if (selectedPubIds.length > 0) {
                        $.ajax({
                            url: self.editTagsUrlValue,
                            type: 'GET',
                            data: {
                                item_ids: selectedPubIds,
                                item_type: 'Publication'
                            }
                        }).done(function (response, status) {
                            const $editTags = $('#edit-tags');
                            $editTags.parent().find('.dropdown-menu').html(response);
                            $editTags.dropdown('update');
                            $editTags.parent().on('hide.bs.dropdown', function () {
                                const event = new Event('save', { bubbles: true });
                                this.querySelector('#tagging-container').dispatchEvent(event);
                            })
                        });
                    }
                },
            }]
        });
        this.datatable.buttons(1, null).containers().prependTo('#tags-button-wrapper');
        $('#edit-tags').parent().addClass('dropdown').append('<div class="dropdown-menu dropdown-menu-right">Loading..</div>');
        this.toggleButtons();
        this.datatable.on('select deselect', () => this.toggleButtons());
    }

    toggleButtons() {
      const selectedRows = this.datatable.rows({ selected: true });
      let archivableRows = 0;
      selectedRows.every((index) => {
        if (selectedRows.row(index).data().status !== "Archived") {
          archivableRows += 1;
        }
      });
      this.datatable.buttons(".requires-selected").enable(selectedRows.count() > 0);
      this.datatable.buttons(".requires-archivable").enable(archivableRows > 0);
    }
}